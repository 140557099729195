import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { TbArrowBigRight } from "react-icons/tb"

export default function Home({ location }) {
  return (
    <Layout pageTitle="Home">
      <section className="reading">
        <article>
          <h2>Music</h2>
          <p>I play bass with a variety of local Kingston artists working in jazz, folk, and rock. I also play an ever-expanding list of other instruments, and have dabbled in recording and production. I am a graduate of the University of Toronto music program for jazz double bass.</p>
          <Link to="music" className="button">
            More Music
            <TbArrowBigRight />
          </Link>
        </article>
        <article>
          <h2>Web Design & Development</h2>
          <p>I've been doing full-stack web development since the late 1990s. I regularly use HTML, CSS, Sass, JavaScript, React, PHP, WordPress, Drupal, Gatsby, and Eleventy. I am available for contract work. Please check out some sites I've worked on:</p>
          <Link to="web" className="button">
            More Web
            <TbArrowBigRight />
          </Link>
        </article>
        <article>
          <h2>Contact</h2>
          <p>Please get in touch if you're interested in finding out more.</p>
          <Link to="contact" className="button">
            Contact
            <TbArrowBigRight />
          </Link>
        </article>
      </section>
    </Layout>
  )
}
